import { useEffect, useState } from 'react'
import { empresaType } from './EmpresasCadastradas';
import ReactInputMask from 'react-input-mask';
import { GiSave } from "react-icons/gi";
import { useMutation } from 'react-query';
import AttToken from '../../services/AttToken';
import Empresas from '../../services/Empresas';
import { toast, ToastContainer } from 'react-toastify';
import { Form, InputBox } from './styles';

const empresaService = new Empresas();

export default function CadastrarEmpresas() {
  const [empresaState, setEmpresaState] = useState<empresaType>({
    nome: '',
    cnpj: '',
    emailNotificacao: '',
    status: 'ativo'
  })

  const { mutate, isLoading } = useMutation({
    mutationKey: ['PUT_EMPRESA'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const empresa: empresaType = {
          nome: empresaState.nome,
          cnpj: empresaState.cnpj,
          emailNotificacao: empresaState.emailNotificacao = '',
          status: empresaState.status
        }
        toast.promise(async () => {
          const response = await empresaService.novaEmpresa(
            token,
            empresa,
          )
          return response
        }, {
          pending: 'Cadastrando empresa',
          success: 'Empresa cadastrada',
          error: 'Erro cadastrar empresa'
        })
      }
    },
    onSuccess: () => setEmpresaState({
      nome: '',
      cnpj: '',
      emailNotificacao: '',
      status: 'ativo'
    })
  })

  return (
    <>
      <ToastContainer />
      <Form onSubmit={(event) => {
        event.preventDefault()
        mutate()
      }}>
        <InputBox>
          Nome:
          <input
            type='text'
            placeholder='Digite o nome da empresa'
            value={empresaState.nome}
            onChange={(event) => setEmpresaState({ ...empresaState, nome: event.target.value })}
            className={`input is-fullwidth`} />
          CNPJ:
          <ReactInputMask
            maskChar={null}
            alwaysShowMask={false}
            placeholder='Entre com o CNPJ'
            className={`input is-fullwidth`}
            value={empresaState.cnpj}
            onChange={(event) => setEmpresaState({ ...empresaState, cnpj: event.target.value })}
            mask="99.999.999/9999-99"
          />
        </InputBox>
        <button
          style={{ marginTop: '10px' }}
          disabled={
            (empresaState.nome?.length <= 0
              || empresaState.cnpj?.length < 18)
            || isLoading
          }
          type='submit'
          className='button is-success is-small'>
          <GiSave />
          &nbsp;Salvar
        </button>
      </Form>
    </>
  )
}