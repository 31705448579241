import { IoMdExit } from "react-icons/io";
import { BsBuildings } from "react-icons/bs";
import { FaChartBar } from "react-icons/fa";
import Login from '../../services/Login';
import { useMutation } from 'react-query';
import { FaRegUserCircle } from "react-icons/fa";
import { HeaderComp, HeaderLink } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const loginService = new Login();

export default function Header() {
  const navigate = useNavigate()
  const location = useLocation();
  const { mutate } = useMutation({
    mutationKey: ['LOGOUT'],
    mutationFn: async () => {
      toast.warn('Saindo!')
      const loggedData = localStorage.getItem('loggedData');
      if (loggedData) {
        const parsed = JSON.parse(loggedData);
        await loginService.logout(parsed?.userToken);
        localStorage.clear();
        window.location.href = window.location.origin + "/";
      }
    }
  })

  return (
    <HeaderComp>
      Interface administrativa Axyma
      <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <HeaderLink onClick={() => navigate('/empresas')} className={`navbar-item ${location.pathname === '/empresas' ? "active" : ""}`}>
              <BsBuildings/>&nbsp;Empresas
            </HeaderLink>
            <HeaderLink onClick={() => navigate('/dashboard')} className={`navbar-item ${location.pathname === '/dashboard' ? "active" : ""}`}>
              <FaChartBar />&nbsp;Dashboard
            </HeaderLink>
            <HeaderLink onClick={() => mutate()} className="navbar-item">
              <IoMdExit />&nbsp;Sair
            </HeaderLink>
          </div>
        </div>
      </nav>
    </HeaderComp>
  )
}