import Header from "../Header/Header";
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import register, { RequestType, userReportsType } from '../../services/PowerBi';
import { toast } from 'react-toastify';
import UserComponent, { userBackObj } from './UserComponent'
import { GrUpdate } from 'react-icons/gr';
import Users from "../../services/User";
import AttToken from "../../services/AttToken";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { Content, Main, Principal } from "./styles";
import { empresaType } from "../Empresas/EmpresasCadastradas";
import Empresas from "../../services/Empresas";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales'
import { FaRegTrashAlt } from "react-icons/fa";

const empresaService = new Empresas();

export type UserAccessType = {
  [userName: string]: string[]
}

export type empresaReportsType = {
  [id: string]: singleEmpresaType
}

export type singleEmpresaType = {
  datasetId: string,
  datasetWorkspaceId: string
  id: string,
  name: string,
  users: {
    name: [key: string],
  }
}
const usersService = new Users();
const servicePowerBi = new register();


let userAccess = {} as UserAccessType

export default function DashboardComponent() {
  const [url, setUrl] = useState<string>('');
  const [empresa, setEmpresa] = useState<{ label: string, value: string } | null>({ label: '', value: '' });
  const [userReports, setUserReports] = useState<userReportsType[] | null>(null);
  const [selectedReport, setSelectedReport] = useState<singleEmpresaType | null>(null);
  const [empresaReports, setEmpresaReports] = useState<singleEmpresaType[] | null>(null)
  const [usersAdded, setUsersAdded] = useState<any>([]);
  const [users, setUsers] = useState<userBackObj[] | null>(null)
  const [workspaceId, setWorkspaceId] = useState('')

  const { data: empresasData } = useQuery({
    queryKey: ['GET_EMPRESAS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await empresaService.getAll(token)
        return response.empresas
      }
    },
    keepPreviousData: true,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { mutate: getUserReports, isLoading, data } = useMutation({
    mutationKey: ['GET_USER_REPORTS'],
    mutationFn: async (empresa: { value: string, label: string }) => {
      setUserReports(null)
      const token = await AttToken();
      if (token) {
        console.log(process.env.REACT_APP_PROJECT_NAME)
        const args = {
          empresa: empresa.value, //'-Lmyih0t3MjeBjSGrNxE',
          userAdm: process.env.REACT_APP_PROJECT_NAME === 'Axyma Administrativo Interno HOMOL' ? token.username : `suporte${empresa?.label.toLowerCase()}`,
        }
        console.log(args)
        const response = await servicePowerBi.getUserReports(token.userToken, args)
        return response
      }
    },
  })

  const { mutate: getEmpresaReports, isLoading: isLoadingEmpresas, data: empresaReportsData } = useMutation({
    mutationKey: ['GET_EMPRESA_REPORTS'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const args = {
          empresa: empresa?.value as string, //'-Lmyih0t3MjeBjSGrNxE',
          userAdm: process.env.REACT_APP_PROJECT_NAME === 'Axyma Administrativo Interno HOMOL' ? token.username : `suporte${empresa?.label.toLowerCase()}`,
        }
        const response = await servicePowerBi.getEmpresaReports(token.userToken, args)
        return response
      }
    },
  })

  const { mutate } = useMutation({
    mutationKey: ['REGISTER_WORKSPACE'],
    mutationFn: async (value: string) => {
      toast.promise(
        async () => {
          const token = await AttToken()
          if (token) {
            const args = {
              empresa: empresa?.value as string,
              userAdm: process.env.REACT_APP_PROJECT_NAME === 'Axyma Administrativo Interno HOMOL' ? token.username : `suporte${empresa?.label.toLowerCase()}`,
              url: value
            }
            const response = await servicePowerBi.registerWorkSpace(token.userToken, args)
            updateWorkspace()
            return response
          }
        },
        {
          pending: 'Salvando workspace',
          success: 'Workspace salvo',
          error: 'Erro ao salvar workspace'
        }
      )
    },
    onSuccess: () => {
      setUrl('')
      getUserReports(empresa as { value: string, label: string })
      getEmpresaReports()
    }
  })

  const { mutate: getUsers, data: userData } = useMutation({
    mutationKey: ['GET_USERS'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await usersService.getAllByCompany(body, empresa?.value as string);
        return result
      }
    },
  })

  const { mutate: updateLicense } = useMutation({
    mutationKey: ['updateLicense'],
    mutationFn: async () => {
      toast.promise(async () => {
        const token = await AttToken()
        if (token) {
          const args = {
            empresa: empresa?.value as string, //'-Lmyih0t3MjeBjSGrNxE',
            userAdm: process.env.REACT_APP_PROJECT_NAME === 'Axyma Administrativo Interno HOMOL' ? token.username : `suporte${empresa?.label.toLowerCase()}`,
          }
          const update: RequestType = { [data[0].datasetWorkspaceId]: { [data[0].id + '/users']: userAccess } }
          const response = await servicePowerBi.manageLicence(token.userToken, args, update)
          return response
        }
      },
        {
          pending: 'Salvando alterações',
          success: 'Alterações salvas',
          error: 'Erro ao salvar alterações'
        })
    },
    onSuccess: () => {
      getUserReports(empresa as { value: string, label: string })
      getEmpresaReports()
    }
  })

  const { mutate: updateWorkspace, isLoading: loadingUpdate } = useMutation({
    mutationKey: ['UPDATE_WORKSPACE'],
    mutationFn: async () => {
      toast.promise(async () => {
        const token = await AttToken()
        if (token && empresaReports) {
          const args = {
            empresa: empresa?.value as string,
            userAdm: process.env.REACT_APP_PROJECT_NAME === 'Axyma Administrativo Interno HOMOL' ? token.username : `suporte${empresa?.label.toLowerCase()}`,
            workspaceId: workspaceId
          }
          const response = await servicePowerBi.powerbiUpdateWorkspace(token.userToken, args)
          return response
        }
      }, {
        pending: 'Atualizando workspace',
        success: 'Atualizado com sucesso',
        error: 'Erro ao atualizar workspace'
      })
    },
    onSuccess: () => {
      getUserReports(empresa as { value: string, label: string })
      getEmpresaReports()
    }
  })

  const { mutate: removeDashBoard } = useMutation({
    mutationKey: ['REMOVE_DASHBOARD'],
    mutationFn: async (workspaceId: string) => {
      toast.promise(async () => {
        const token = await AttToken()
        if (token) {
          await servicePowerBi.deleteWorkspace(token.userToken, token.username, token.userEmpresa, empresa?.value as string)
        }
      }, {
        pending: 'Removendo workspace',
        success: 'Workspace removido',
        error: 'Erro ao remover workspace'
      })
    },
    onSuccess: () => getUserReports(empresa as { value: string, label: string })
  })

  useEffect(() => {
    if (!isLoading) {
      if (data?.length) {
        userAccess = data[0]?.users
        setUserReports(data)
        if (userData?.length) {
          setUsersAdded(userData?.filter((e: userBackObj) => {
            if (data[0]?.users) {
              e.matricula = data[0]?.users[e.id]
              return (
                Object?.keys(data[0]?.users).includes(e.id)
              )
            } else return e
          }))
          setUsers(userData?.filter((e: userBackObj) => !usersAdded?.some((user: any) => user.nome === e.nome)))
        }
      }
    } else {
      setUserReports(null)
      setEmpresaReports(null)
      setUsers(null)
      setUsersAdded(null)
      setSelectedReport(null)
    }
  }, [isLoading, userReports, userData, data, usersAdded])

  useEffect(() => {
    if (empresaReportsData)
      setWorkspaceId(String(Object.keys(empresaReportsData)))
  }, [empresaReportsData])

  const transform = useCallback((obj: empresaReportsType) => {
    if (!isLoadingEmpresas && obj) {
      const keys = Object?.keys(obj)
      const newObj = Object?.values(obj[keys[0]])
      setEmpresaReports(newObj as unknown as singleEmpresaType[])
    }
  }, [isLoadingEmpresas])

  useEffect(() => {
    if (empresaReportsData) {
      transform(empresaReportsData)
    }
  }, [empresaReportsData, transform])

  useEffect(() => {
    if ((userReports as userReportsType[])?.length > 0) {
      getEmpresaReports()
      getUsers()
    }
  }, [userReports, getEmpresaReports, getUsers])

  const renderSwitch = (value: string) => {
    switch (String(value)) {
      case 'rls_gerente':
        return (
          'Gerente'
        )
      case 'rls_cliente':
        return (
          'Cliente'
        )
      case 'rls_conjunto':
        return (
          'Conjunto'
        )

      case 'rls_empresa':
        return (
          'Empresa'
        )
      default:
        return (
          'Todos'
        )
    }
  }

  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Nome', width: 250 },
    { field: 'email', headerName: 'E-mail', width: 250 },
    { field: 'role', headerName: 'Perfil', width: 100 },
    {
      field: 'matricula', headerName: 'Função', width: 100, valueFormatter: (params) => {
        return renderSwitch(params[0])
      }
    },
    {
      field: 'remove', headerName: 'Remover', width: 100, renderCell: (params: GridRenderCellParams) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            className="button is-danger is-small"
            onClick={() => {
              setUsersAdded(usersAdded.filter((e: userBackObj) => e.id !== params.id))
              delete userAccess[params.id]
              setUsers([...users as userBackObj[], params.row])
            }}
          >
            Remover&nbsp;<FaRegTrashAlt />
          </button>
        </div>
      )
    },
  ];

  return (
    <Main data-theme="light">
      <Header />
      <span style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>Dashboards</span>
      <Content>
        <Principal>
          {!empresasData ? <Skeleton height={60} /> :
            <Autocomplete
              className='column'
              noOptionsText={'Sem equipes correspondentes'}
              size="small"
              id="disable-close-on-select"
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={empresasData
                ?.sort((a: empresaType, b: empresaType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.filter((elem: empresaType) => elem.status === 'ativo')
                ?.map((option: empresaType) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              onChange={(event: any, newValue: { label: string, value: string } | null) => {
                setEmpresa(newValue)
                getUserReports(newValue as { label: string, value: string })
              }}
              renderInput={(params) => <TextField {...params} label={'Selecione a empresa'} />}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
            />}
          {isLoading ? <Skeleton height={60} /> : <>
            {(empresa as { value: string, label: string })?.value.length > 0 && <>
              {!userReports
                ? <label>
                  <p style={{ textAlign: 'left', margin: '2px', fontWeight: '600' }}>Insira uma URL de relatório do PowerBI para cadastrar:</p>
                  <div style={{ display: 'flex' }}>
                    <input value={url} onChange={(e) => {
                      setUrl(e.target.value)
                    }} className='input is-small is-fullwidth' />
                    <button onClick={(e) => {
                      e.preventDefault()
                      mutate(url)
                    }}
                      disabled={url === ''}
                      type='submit'
                      className='button is-success is-small'
                    >
                      Enviar url
                    </button>
                  </div>
                </label> :
                <>
                  {!userReports
                    ? <>
                      <Skeleton variant="rounded" height={35} width={150} sx={{ marginBottom: '10px' }} />
                      <Skeleton variant="rounded" height={40} />
                    </>
                    : <>
                      <button
                        disabled={loadingUpdate}
                        onClick={() =>
                          updateWorkspace()
                        }
                        style={{ marginBottom: '10px' }}
                        className='button is-success is-small'>
                        Atualizar workspace&nbsp;
                        <GrUpdate />
                      </button>
                      <Autocomplete
                        className='column'
                        noOptionsText={'Sem equipes correspondentes'}
                        size="small"
                        id="disable-close-on-select"
                        filterSelectedOptions
                        disabled={!empresaReports?.length}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        options={empresaReports
                          ?.sort((a: singleEmpresaType, b: singleEmpresaType) => { return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : - 1 })
                          ?.map((option: singleEmpresaType) => ({
                            label: option.name,
                            value: option.id,
                          })) || []}
                        onChange={(event: any, newValue: { label: string, value: string } | null) => {
                          const userReport = empresaReports?.find((ele) => ele.id === newValue?.value)
                          if (userReport) {
                            setSelectedReport(userReport)
                          }
                        }}
                        renderInput={(params) => <TextField {...params} label={'Selecione a dashboard'} />}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.value}>
                            <span>{option.label}</span>
                          </li>)}
                      />
                    </>
                  }
                </>
              }
            </>}
          </>}
          {selectedReport &&
            <div style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex', marginBottom: '.5em' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ marginBottom: '.5em', display: 'flex', width: 'fit-content' }}>
                    <button
                      disabled={isLoading || !usersAdded?.length}
                      onClick={() =>
                        updateLicense()
                      } className='button is-small is-success'>Salvar alterações
                    </button>
                  </div>
                  Usuários adicionados
                  <DataGrid
                    editMode="row"
                    rows={usersAdded}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[10, 25]}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  />
                </div>
              </div>
              Usuários para adicionar
              <UserComponent
                usersAdded={usersAdded}
                setUsersAdded={setUsersAdded}
                onChanged={(e) => {
                  userAccess[e.id] = [e.value]
                  setUsers(userData.filter((el: any) => el.id !== e.id))
                }}
                userData={users
                  ?.sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1 }) as userBackObj[]} />
            </div>}
        </Principal>
      </Content>
    </ Main>
  );
}